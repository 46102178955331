import { observable, action, computed } from "mobx";
import { deleteData, getData, postData, putData } from "../plugins/auth/auth";
import {
  GOAL_TEMPLATES,
  GOAL_TEMPLATES_ROUTE,
  GOALS,
  GOALS_ROUTE,
} from "../config";
import { IGoalTemplate, IGoal, INewGoal } from "../shared/interfaces";
import _ from "lodash";
import { message, notification } from "antd";

class GoalStore {
  // Observables
  @observable
  public baseInit: any = {
    headers: {},
    response: true,
  };
  @observable
  public goalTemplates: IGoalTemplate[] = [];
  @observable
  public goals: IGoal[] = [];
  @observable
  public activeGoals: IGoal[] = [];
  @observable
  public inActiveGoals: IGoal[] = [];
  @observable
  public priorityGoals: IGoal[] = [];
  @observable
  public completedGoals: IGoal[] = [];
  @observable
  public activeGoalTemplate = {};
  @observable
  public activeGoal = {};
  @observable
  public createGoalModal = false;
  @observable
  public updateGoalModal = false;

  // Computed Values
  // @computed get activeGoals() {
  //   return this.goals.filter(
  //     (goal: IGoal) => goal.status !== "completed" && goal.isActive === 1
  //   );
  // }

  @computed get usedGoalTemplateIds() {
    return _.map(this.goals, "templateId");
  }

  // Actions
  @action.bound
  public async getGoalTemplates() {
    const res = await getData(
      GOAL_TEMPLATES,
      GOAL_TEMPLATES_ROUTE,
      this.baseInit
    ).catch((err) => {
      console.log("Error from getGoalTemplates: ", err);
    });

    if (res && res.request.status === 200) {
      this.goalTemplates = res.data.goalTemplates;
    }
  }

  @action.bound
  public async getGoals(params?: string) {
    const res = await getData(
      GOALS,
      `${GOALS_ROUTE}${params ? `?${params}` : ``}`,
      this.baseInit
    ).catch((err) => {
      console.log("Error from getGoals: ", err);
    });
    if (res && res.request.status === 200) {
      switch (params) {
        case "isActive=0":
          this.inActiveGoals = res.data.goals.filter(
            (goal: IGoal) => goal.status === "incomplete"
          );
          return this.inActiveGoals;
        case "isActive=1&isPriority=0":
          this.activeGoals = res.data.goals.filter(
            (goal: IGoal) => goal.status !== "complete" && goal.isActive === 1
          );
          return this.activeGoals;
        case "isPriority=1":
          this.priorityGoals = res.data.goals;
          return this.priorityGoals;
        // case "isActive=1":
        //   this.activeGoals = res.data.goals;
        //   return this.activeGoals;
        case "status=complete":
          this.completedGoals = res.data.goals;
          return this.completedGoals;
        default:
          this.goals = res.data.goals.filter(
            (goal: IGoal) => goal.status !== "complete" || goal.isActive === 1
          );
          return this.goals;
      }
    }
  }

  @action.bound
  public async getGoal(id: string) {
    const res = await getData(
      GOALS,
      `${GOALS_ROUTE}/${id}`,
      this.baseInit
    ).catch((err) => {
      console.log("Error from getGoal: ", err);
    });

    if (res && res.request.status === 200) {
      this.activeGoal = res.data.goal;
      return this.goals;
    }
  }

  @action.bound
  public async createGoal(newGoal: INewGoal) {
    const { name, templateId } = newGoal;
    const description =
      newGoal.description === undefined ? "" : newGoal.description;
    let init = this.baseInit;
    const atts = {
      ...(description.length > 0 && { description }),
      ...(templateId && { templateId }),
    };
    init.body = {
      isActive: 1,
      name,
      status: "incomplete",
      ...atts,
    };

    let resErr;
    await postData(GOALS, GOALS_ROUTE, init)
      .then((res) => {
        this.activeGoals.push(res.data);
      })
      .catch((err) => {
        resErr = err.message;
      });

    if (resErr) {
      return resErr;
    }
  }

  @action.bound
  public async updateGoal(
    goal: IGoal,
    isActive: number,
    isPriority: number,
    action: string
  ) {
    message.warning("Please wait while we update your goal");
    let init = this.baseInit;
    const description = goal.description === undefined ? "" : goal.description;
    const atts = description.length === 0 ? {} : { description };
    init.body = {
      id: goal.id,
      userId: goal.userId,
      name: goal.name,
      createdAt: goal.createdAt,
      isActive: isActive,
      status: goal.status ? goal.status : "incomplete",
      isPriority: isPriority,
      mentorId: goal.mentorId,
      isDeleted: goal.isDeleted,
      // mentor: goal.mentor,
      ...atts,
    };
    if (goal.teamId) {
      init.body = {
        ...init.body,
        teamId: goal.teamId,
        // team: goal.team,
      };
    }
    let sentTo = "";
    let sentFrom = "";
    if (action) {
      sentTo = action.split("sendTo")[1].split("From")[0];
      sentFrom = action.split("From")[1];
    }
    if (goal.completedOn) init.body.completedOn = goal.completedOn;

    let resErr;
    await putData(GOALS, GOALS_ROUTE, init)
      .then(() => {
        message.destroy();
        if (sentTo === "Active" && sentFrom === "Active") {
          const goalIndex = this.activeGoals.findIndex(
            (activeGoal) => activeGoal.id === goal.id
          );
          if (goalIndex !== -1) {
            return (this.activeGoals[goalIndex] = goal); // replace 'property' and 'new value' with the actual property and value
          }
        } else if (sentTo === "Complete" && sentFrom === "Complete") {
          const goalIndex = this.completedGoals.findIndex(
            (completedGoal) => completedGoal.id === goal.id
          );
          if (goalIndex !== -1) {
            return (this.completedGoals[goalIndex] = goal);
          }
        } else if (sentTo === "Priority" && sentFrom === "Priority") {
          const goalIndex = this.priorityGoals.findIndex(
            (priorityGoal) => priorityGoal.id === goal.id
          );
          if (goalIndex !== -1) {
            return (this.priorityGoals[goalIndex] = goal);
          }
        } else if (sentTo === "Inactive" && sentFrom === "Inactive") {
          const goalIndex = this.inActiveGoals.findIndex(
            (inActiveGoal) => inActiveGoal.id === goal.id
          );
          if (goalIndex !== -1) {
            return (this.inActiveGoals[goalIndex] = goal);
          }
        }
        if (sentTo === "Active") {
          if (
            this.activeGoals.find(
              (activeGoal: IGoal) => activeGoal.id === goal.id
            )
          )
            return;
          else this.activeGoals.push(goal);
        }
        if (sentTo === "Priority") {
          if (
            this.priorityGoals.find(
              (priorityGoal: IGoal) => priorityGoal.id === goal.id
            )
          )
            return;
          else this.priorityGoals.push(goal);
        }
        if (sentTo === "Complete") {
          if (
            this.completedGoals.find(
              (completedGoal: IGoal) => completedGoal.id === goal.id
            )
          )
            return;
          else this.completedGoals.push(goal);
        }
        if (sentTo === "Inactive") {
          if (
            this.inActiveGoals.find(
              (inActiveGoal: IGoal) => inActiveGoal.id === goal.id
            )
          )
            return;
          else this.inActiveGoals.push(goal);
        }
        if (sentFrom === "Active") {
          return (this.activeGoals = this.activeGoals.filter(
            (activeGoal: IGoal) => activeGoal.id !== goal.id
          ));
        }
        if (sentFrom === "Priority") {
          return (this.priorityGoals = this.priorityGoals.filter(
            (priorityGoal: IGoal) => priorityGoal.id !== goal.id
          ));
        }
        if (sentFrom === "Complete") {
          return (this.completedGoals = this.completedGoals.filter(
            (completedGoal: IGoal) => completedGoal.id !== goal.id
          ));
        }
        if (sentFrom === "Inactive") {
          return (this.inActiveGoals = this.inActiveGoals.filter(
            (inActiveGoal: IGoal) => inActiveGoal.id !== goal.id
          ));
        }
      })
      .catch((err) => {
        console.log("Error from updateGoal: ", err);
        resErr = err.message;
      });

    if (resErr) {
      return resErr;
    }
  }

  @action.bound
  public async shareGoal(goalId: string, action: string) {
    let init = this.baseInit;
    let resErr;
    await postData(GOALS, `${GOALS_ROUTE}/${goalId}/share`, init)
      .then((res) => {})
      .catch((err) => {
        console.log("Error from sharing Goal: ", err);
        resErr = err.message;
      });

    if (resErr) {
      return resErr;
    }
    if (action === "refetchInactiveGoals") this.getGoals("isActive=0");
    if (action === "refetchActiveGoals")
      this.getGoals("isActive=1&isPriority=0");
    if (action === "refetchPriorityGoals") this.getGoals("isPriority=1");
    if (action === "refetchCompleteGoals") this.getGoals("status=complete");
  }

  @action.bound
  public async disconnectOrDeleteGoal(
    goalId: string,
    deleteGoal: boolean,
    action: string
  ) {
    let init = this.baseInit;

    let resErr;
    await deleteData(
      GOALS,
      `${GOALS_ROUTE}/${goalId}?action=${deleteGoal ? "delete" : "unshare"}`,
      init
    ).catch((err) => {
      console.log("Error from disconnectOrDeleteGoal: ", err);
      resErr = err.message;
    });

    if (resErr) {
      return resErr;
    }
    if (action === "refetchInactiveGoals") this.getGoals("isActive=0");
    if (action === "refetchActiveGoals")
      this.getGoals("isActive=1&isPriority=0");
    if (action === "refetchPriorityGoals") this.getGoals("isPriority=1");
    if (action === "refetchCompleteGoals") this.getGoals("status=complete");
  }
}

export default new GoalStore();

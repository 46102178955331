// Packages
import React from "react";
import {
  BarChartOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Tooltip } from "antd";
import { action, computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { Popconfirm, Button, Progress, message } from "antd";
import moment from "moment";

// Components
import CheckBox from "../../CheckBox/CheckBox";
import { track } from "../../../plugins/analytics";

// Styles
import "./ActivityCard.scss";
// Type Definitions
import { IActivity } from "../../interfaces";
import CalenderSVG from "../../../components/svgComponents/CalenderSVG";
import { IonButton, IonIcon, IonItem, IonList, IonPopover } from "@ionic/react";
import { ellipsisVertical } from "ionicons/icons";
import EditSVG from "../../../components/svgComponents/EditSVG";
import DeleteSVG from "../../../components/svgComponents/DeleteSVG";
import { FlagNotFilledSVG } from "../../../components/svgComponents/FlagNotFilledSVG";
import { TimesSVG } from "../../../components/svgComponents/TimesSVG";
import { FilledStarSVG } from "../../../components/svgComponents/FilledStarSVG";
// import CircleProgress from "../../CircleProgress/CircleProgress";
const ButtonGroup = Button.Group;

interface IProps {
  activity: IActivity;
  goalName: string;
  showYear?: boolean;
  historicLookup?: string;
  activityStore?: any;
  completedOn?: string;
  isGoalDetailForm?: boolean;
  completionType?: string;
  showFlag?: boolean;
  removeActivity?: (activity: IActivity) => void;
}

@inject("activityStore")
@observer
class ActivityCard extends React.Component<IProps> {
  @computed get routineContext() {
    const { activity } = this.props;
    if (this.historicDate !== "N/A") {
      return activity.history[this.historicDate];
    } else {
      return activity;
    }
  }

  @computed get historicDate() {
    const {
      historicLookup,
      activity: { history },
    } = this.props;
    if (historicLookup && history) {
      const dayOf = moment(historicLookup).format("YYYY-MM-DD");
      const dayAfter = moment(historicLookup)
        .add(1, "days")
        .format("YYYY-MM-DD");
      if (history[dayOf]) {
        return dayOf;
      } else if (history[dayAfter]) {
        return dayAfter;
      } else {
        return "N/A";
      }
    } else {
      return "N/A";
    }
  }

  @computed get isTaskComplete() {
    return this.complete === true && this.props.activity.type === "task";
  }

  @computed get activityTitleClass() {
    let title = this.isTaskComplete ? "task-complete-title" : "task-title";
    return title.trim();
  }

  @computed get frequency() {
    return this.routineContext.frequency || 0;
  }

  @observable complete = this.routineContext.status === "complete";
  @observable timesCompleted = this.routineContext.timesCompleted || 0;

  @action.bound
  public openEditModal = async () => {
    this.props.activityStore.updateActivityType(this.props.activity.type);
    this.props.activityStore.activeActivity = this.props.activity;
    this.props.activityStore.updateActivityModal = true;
    this.props.activityStore.isGoalDetailForm = this.props.isGoalDetailForm
      ? true
      : false;
  };

  @action.bound
  public archiveActivity = async () => {
    await this.props.activityStore.updateActivity(this.props.activity, 0);
    if (this.props.removeActivity) {
      this.props.removeActivity(this.props.activity);
    }
    message.success("Action deleted");
    track("Action deleted");
  };

  @action.bound
  public updateStatus = async (event: React.FormEvent) => {
    this.complete = !this.complete;
    const { status } = this.props.activity;
    const newStatus = status === "complete" ? "incomplete" : "complete";
    const completedOn =
      newStatus === "complete" ? new Date().toISOString() : null;
    const resError = await this.props.activityStore.updateActivity({
      ...this.props.activity,
      status: newStatus,
      ...(completedOn && { completedOn }),
    });
    track("Activity Status Updated", {
      status: newStatus,
    });
    if (resError) {
      message.error(resError);
      this.complete = !this.complete;
    }
  };

  @action.bound
  public reviseHistory = (
    newTimesCompleted: number,
    newStatus: string,
    completedOn: string | null
  ) => {
    const {
      activity: { history },
    } = this.props;
    let revisedHistory;
    if (this.props.historicLookup) {
      revisedHistory = {
        ...history,
        ...{
          [this.historicDate !== "N/A"
            ? this.historicDate
            : moment(this.props.historicLookup).format("YYYY-MM-DD")]: {
            goalId: this.props.activity.goalId,
            paused:
              history[
                this.historicDate !== "N/A"
                  ? this.historicDate
                  : moment(this.props.historicLookup).format("YYYY-MM-DD")
              ].paused || 0,
            frequency: this.frequency,
            ...history[
              this.historicDate !== "N/A"
                ? this.historicDate
                : moment(this.props.historicLookup).format("YYYY-MM-DD")
            ],
            ...(completedOn && { completedOn }),
            timesCompleted: newTimesCompleted,
            status: newStatus,
          },
        },
      };
    }
    return revisedHistory;
  };

  @action.bound
  public updateTimesCompleted = async (direction: string) => {
    const lastTimesCompleted = this.timesCompleted;
    const times =
      direction === "up" ? this.timesCompleted + 1 : this.timesCompleted - 1;
    const newTimesCompleted = times >= 0 ? times : 0;
    this.timesCompleted = newTimesCompleted;
    const newStatus =
      newTimesCompleted >= this.frequency ? "complete" : "incomplete";
    const completedOn =
      newStatus === "complete" ? new Date().toISOString() : null;
    const revisedHistory = this.reviseHistory(
      newTimesCompleted,
      newStatus,
      completedOn
    );
    track("Routine Progress Logged", {
      timesCompleted: newTimesCompleted,
      frequency: this.frequency,
    });
    const resError = await this.props.activityStore.updateActivity({
      ...this.props.activity,
      ...(completedOn && { completedOn }),
      ...(this.props.historicLookup && { history: revisedHistory }),
      ...(!this.props.historicLookup && {
        timesCompleted: newTimesCompleted,
        status: newStatus,
      }),
    });
    if (resError) {
      message.error(resError);
      this.timesCompleted = lastTimesCompleted;
    }
  };

  public viewRoutineProgress = (type: string, name: string) => {
    this.props.activityStore.activeRoutine = this.props.activity;
    this.props.activityStore.createRoutineProgressModal = true;
    track("Routine Progress Viewed");
  };

  renderDetails() {
    const {
      activity: {
        completionDate,
        completedOn,
        status,
        type,
        completionType,
        updatedAt,
      },
      showYear,
      showFlag,
    } = this.props;
    const completedOnDate = moment(
      completedOn ? completedOn : updatedAt
    ).format("DD MMM/YYYY");
    const dateDisplay = status === "complete" ? "none" : "";
    const overdueClass = moment(completionDate).isBefore(
      moment().startOf("day")
    )
      ? "over-due-date"
      : "";
    if (type === "task") {
      if (status === "incomplete") {
        if (completionType === "soon" || completionType === "someday") {
          if (showFlag) {
            return <div className="flag-box">{completionType}</div>;
          }
          return;
        }
      }
      let displayDate = moment(completionDate).format("MMM DD, YYYY");
      if (this.props.activity.status === "complete") {
        displayDate = moment(completedOnDate).format("MMM DD, YYYY");
        return (
          <Tooltip title={<span className="M">Completed</span>}>
            <div className={`date`}>
              <CalenderSVG />
              {displayDate}
            </div>
          </Tooltip>
        );
      }
      // showYear
      //   ? moment(completionDate).format("MM/DD/YYYY")
      return (
        <div
          className={`date ${overdueClass}`}
          style={{ display: dateDisplay }}
        >
          <CalenderSVG color={overdueClass ? "#FF7D6B" : ""} />
          {displayDate}
        </div>
      );
    } else {
      return (
        <div className={"activityMarginLeft"}>
          <ButtonGroup>
            <Button
              onClick={() => this.updateTimesCompleted("down")}
              icon={<MinusOutlined />}
              size="small"
            />
            <div className={"divideLine"}></div>
            <Button
              onClick={() => this.updateTimesCompleted("up")}
              icon={<PlusOutlined />}
              size="small"
            />
          </ButtonGroup>
        </div>
      );
    }
  }

  renderProgress() {
    const { activity } = this.props;
    if (activity.type === "task") {
      return (
        <div>
          <CheckBox
            htmlFor={activity.id}
            checked={this.complete}
            onChange={this.updateStatus}
            type={"task"}
          />
        </div>
      );
    } else {
      if (this.timesCompleted === this.frequency) {
        return (
          <div className="routine-complete">
            <CheckBox
              htmlFor={activity.id}
              checked={this.timesCompleted === this.frequency}
              type={"routine"}
            />
          </div>
        );
      } else if (this.timesCompleted > this.frequency) {
        return (
          <div className="extra-routine">
            <p>+{this.timesCompleted - this.frequency}</p>
          </div>
        );
      } else {
        return (
          <Progress
            className="custom-progress"
            type="circle"
            width={28}
            percent={(this.timesCompleted / this.frequency || 0) * 100}
            format={(percent) => ``}
            strokeColor={"#8E24AA"}
          />
        );
      }
    }
  }

  render() {
    const {
      activity,
      activity: { name },
    } = this.props;
    return (
      <div
        className={`${
          activity.type === "routine" ? "activity-routine" : "activity-task"
        }`}
      >
        <div className="activity-row">
          <div className="activity-row-left">
            {this.renderProgress()}
            <div className="activity-row-titles">
              <span
                className={this.activityTitleClass}
                onClick={() => {
                  if (activity.type === "routine")
                    this.viewRoutineProgress("type", "name");
                }}
              >
                {name}
              </span>
            </div>
          </div>
          <div className="activity-row-right align-items-center">
            {activity && activity.goal && activity.goal.isPriority === 1 && (
              <Tooltip placement="bottomRight" title={"Priority"}>
                <div className="is-priority">
                  <FilledStarSVG />
                </div>
              </Tooltip>
            )}
            <div className="activity-row-icons">
              <div className="kebabMenu">
                <KebabMenu
                  editActivity={this.openEditModal}
                  resetActivities={this.archiveActivity}
                  viewRoutineProgress={this.viewRoutineProgress}
                  activityType={activity.type}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="activity-row-2 flex items-center">
          {activity.name && (
            <div className="activityLeft truncate">
              <div
                className={`${
                  (activity && activity.goal && activity.goal.name) ||
                  this.props.goalName
                    ? ""
                    : "no-goal-selected"
                } activityPadding`}
              >
                <span className="icon min-w-10 flex items-center">
                  <FlagNotFilledSVG
                    color={
                      (activity && activity.goal && activity.goal.name) ||
                      this.props.goalName
                        ? undefined
                        : "#FF7D6B"
                    }
                  />{" "}
                </span>
                <p
                  className={`${
                    this.props.isGoalDetailForm
                      ? "goal-text-detail-form"
                      : "goal-text"
                  } truncate`}
                >
                  {(activity && activity.goal && activity.goal.name) ||
                    this.props.goalName ||
                    "No goal selected"}{" "}
                </p>
              </div>
            </div>
          )}
          {activity.frequency && (
            <div className="activityLeft min-w-max">
              <div className="activityPadding">
                <TimesSVG /> {activity.frequency} times/week{" "}
              </div>
            </div>
          )}
          <div className="activityLeft min-w-max flex items-center flexBoxRow gapS">
            {" "}
            {activity.frequency && (
              <>
                {this.timesCompleted} / {this.frequency}
              </>
            )}{" "}
            {this.renderDetails()}
          </div>
        </div>
      </div>
    );
  }
}

interface KebabMenuProps {
  editActivity: () => void;
  resetActivities: () => void;
  viewRoutineProgress: (type: string, name: string) => void;
  activityType: string;
}

interface KebabMenuState {
  showPopover: boolean;
  event?: Event;
}

class KebabMenu extends React.Component<KebabMenuProps, KebabMenuState> {
  constructor(props: KebabMenuProps) {
    super(props);
    this.state = {
      showPopover: false,
    };
  }

  handlePopoverOpen = (e: React.MouseEvent) => {
    this.setState({ showPopover: true, event: e.nativeEvent });
  };

  handlePopoverClose = () => {
    this.setState({ showPopover: false });
  };

  handleEditActivityClick = () => {
    this.props.editActivity();
    this.setState({ showPopover: false });
  };
  handleArchiveActivitiesClick = () => {
    this.props.resetActivities();
    this.setState({ showPopover: false });
  };
  handleViewRoutineProgress = () => {
    this.props.viewRoutineProgress("type", "name");
    this.setState({ showPopover: false });
  };

  render() {
    return (
      <>
        <IonPopover
          cssClass={"popover"}
          isOpen={this.state.showPopover}
          event={this.state.event}
          onDidDismiss={this.handlePopoverClose}
        >
          <IonList className="small-width">
            {this.props.activityType === "routine" && (
              <IonButton
                expand="full"
                className="no-background"
                onClick={this.handleViewRoutineProgress}
              >
                <div className="edit">
                  <BarChartOutlined className="small-bard-icon" />
                  View Progress
                </div>
              </IonButton>
            )}
            <IonButton
              expand="full"
              className="no-background"
              onClick={this.handleEditActivityClick}
            >
              <div className="edit">
                <EditSVG />
                Edit
              </div>
            </IonButton>
            <Popconfirm
              title="Are you sure you want to delete this action?"
              onConfirm={this.handleArchiveActivitiesClick}
              okText="Delete action"
              cancelText="Keep it"
            >
              <IonButton expand="full" className="no-background">
                <div className="delete">
                  <DeleteSVG />
                  <p>Delete</p>
                </div>
              </IonButton>
            </Popconfirm>
            {/*
            <IonItem className="small-width">
              <IonButton expand="full" className="no-background">
                <EditOutlined
                  onClick={() => this.handleEditGoalClick()}
                  className="icon-button"
                />
              </IonButton>
            </IonItem>
              */}
          </IonList>
        </IonPopover>
        <button
          className="menu-button-activity"
          onClick={(e) => this.handlePopoverOpen(e)}
        >
          <IonIcon icon={ellipsisVertical} />
        </button>
      </>
    );
  }
}

export default ActivityCard;

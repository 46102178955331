// Packages
import { IonPage, IonButton } from "@ionic/react";
import React from "react";
import { computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { Link, RouteComponentProps } from "react-router-dom";
// Components
import { Modal, notification } from "antd";
import HomeActionsSVG from "../../components/svgComponents/HomeActionsSVG";
import ReflectionSVG from "../../components/svgComponents/ReflectionSVG";
import ArrowUpSVG from "../../components/svgComponents/ArrowUpSVG";
import HomeActionsMobileSVG from "../../components/svgComponents/HomeActionsMobileSVG";
import ReflectionMobileSVG from "../../components/svgComponents/ReflectionMobileSVG";
import MenuSVG from "../../components/svgComponents/MenuSVG";
import BrandLogoDark from "../../components/svgComponents/BrandLogoDark";
import BrandLogoLight from "../../components/svgComponents/BrandLogoLight";
import "./Home.scss";
import { PlayCircleOutlined } from "@ant-design/icons";

interface IProps extends RouteComponentProps {
  userStore: any;
}

@inject("userStore")
@observer
class Home extends React.Component<IProps> {
  @observable public showModal = false;

  @observable public inspirationImages = [
    {
      src: "/assets/homePageIcons/yoga.png",
      alt: "Start yoga classes",
    },
    {
      src: "/assets/homePageIcons/books.png",
      alt: "Read productivity books",
    },
    {
      src: "/assets/homePageIcons/marathon.png",
      alt: "Prepare for marathon",
    },
    {
      src: "/assets/homePageIcons/pottery.png",
      alt: "Learn pottery",
    },
    {
      src: "/assets/homePageIcons/salad.png",
      alt: "Salad days",
    },
    {
      src: "/assets/homePageIcons/meditate.png",
      alt: "Meditate",
    },
    {
      src: "/assets/homePageIcons/watercolors.png",
      alt: "Learn watercolors",
    },
    {
      src: "/assets/homePageIcons/drivingTest.png",
      alt: "Driving test",
    },
    {
      src: "/assets/homePageIcons/getProductive.png",
      alt: "Get productive",
    },
    {
      src: "/assets/homePageIcons/photography.png",
      alt: "Learn photography",
    },
  ];

  @observable public testimonials = [
    {
      name: "John Doe,Design",
      description:
        "Goalden Hour has helped me stay focused on my goals and has made me more productive. I am able to track my progress and see how far I have come.",
    },
    {
      name: "Jane Doe,CEO",
      description:
        "I love the simplicity of Goalden Hour. It has helped me stay on track and has made me more productive. I am able to track my progress and see how far I have come.",
    },
    {
      name: "John Smith,Developer",
      description:
        "So many paths lead to success -- all you need to do is find your groove. Goalden Hour helps you discover new ways to grow & improve so you don’t have to start from scratch.",
    },
    {
      name: "Lisa Smith,Design Lead",
      description:
        "Goalden Hour has helped me stay focused on my goals and has made me more productive. I am able to track my progress and see how far I have come.",
    },
  ];

  @computed get signedIn() {
    return this.props.userStore.currentUser.isAuthenticated;
  }

  public renderSignIn = ({
    disableKebabMenu,
  }: {
    disableKebabMenu: boolean;
  }) => {
    if (!this.signedIn) {
      return (
        <div
          className={`flexBoxRow ${
            disableKebabMenu ? null : "kebabMenu"
          } mobile-col`}
        >
          <Link to="/signin" className="sign-in-link mont M">
            <IonButton className="home-button fw-800" color="medium-light">
              Log In
            </IonButton>
          </Link>
          <Link to="/signup" className="sign-in-link mont M">
            <IonButton className="home-button fw-800" color="quarternary">
              Get Started
            </IonButton>
          </Link>
        </div>
      );
    } else {
      return (
        <Link to="/goals" className="mont M">
          <IonButton className="home-button fw-800" color="quarternary">
            Enter App
          </IonButton>
        </Link>
      );
    }
  };

  public renderCta = () => {
    if (!this.signedIn) {
      return (
        <Link to="/signup">
          <IonButton className="home-button" color="quarternary">
            Get Started
          </IonButton>
        </Link>
      );
    } else {
      return (
        <Link to="/goals">
          <IonButton className="home-button" color="quarternary">
            Enter App
          </IonButton>
        </Link>
      );
    }
  };

  public scrollToTop = () => {
    const element = document.getElementById("header");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  public toggleMenu = () => {
    const menu = document.getElementById("menu");
    if (menu) {
      if (menu.classList.contains("show")) {
        menu.classList.remove("show");
        menu.classList.add("hide");
      } else {
        menu.classList.remove("hide");
        menu.classList.add("show");
      }
    }
  };

  render() {
    return (
      <IonPage>
        <div className="overflow-container">
          <div className="container">
            <div className="navbar z-1000" id="header">
              <Link to="/" className="navbar-link logo-link">
                {/* <img
                  src={`${process.env.PUBLIC_URL}/assets/logomark.svg`}
                  className="logomark"
                  alt="Goalden Hour logomark"
                />
                <span className="logo-title" style={{ color: "#000" }}>
                  Goalden Hour
                </span> */}
                <BrandLogoDark />
              </Link>
              {window.innerWidth < 600 ? (
                <IonButton
                  color="none"
                  className="kebabMenu"
                  onClick={() => {
                    this.toggleMenu();
                  }}
                >
                  <MenuSVG />
                </IonButton>
              ) : (
                <div className="sign-in-link">
                  {this.renderSignIn({ disableKebabMenu: false })}
                </div>
              )}
            </div>
            <div className="menu" id="menu">
              {this.renderSignIn({ disableKebabMenu: true })}
            </div>
            <div className="hero-container pt-10">
              <div className="cta-container">
                <div className="cta">
                  <h1 className="tagline">Manage and Master your life</h1>
                  <p className="description">
                    Transform your goals into unstoppable momentum
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {this.renderCta()}
                    <IonButton
                      className="cta-button"
                      fill="clear"
                      onClick={() => (this.showModal = true)}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <span>
                        <PlayCircleOutlined size={32} />
                      </span>
                      <span
                        style={{
                          marginLeft: "5px",
                        }}
                      >
                        Watch Video
                      </span>
                    </IonButton>
                    {/* <a
                      href="https://youtu.be/FFU5eL-yIBg?si=7BPD8mgSA2enPRll"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IonButton className="cta-button" fill="clear">
                        <span>
                          <PlayCircleOutlined size={32} />
                        </span>
                        <span>Watch Video</span>
                      </IonButton>
                    </a> */}
                  </div>
                </div>
              </div>
              <div className="sun">
                {window.innerWidth < 768 ? (
                  <img
                    className="sun-img"
                    src="/assets/mountain-mobile.png"
                    alt="mountain"
                  />
                ) : (
                  <img
                    className="sun-img"
                    src="/assets/mountain.png"
                    alt="mountain"
                  />
                )}
              </div>
            </div>
          </div>
          <p className="desc-20 margin-top-bottom">
            Finally a tool to help you manage all the moving parts of your life
            in one place so you can prioritize and plan for what truly matters.
          </p>
          <div className="clarity-container">
            {window.innerWidth < 768 ? (
              <HomeActionsMobileSVG />
            ) : (
              <HomeActionsSVG />
            )}

            <div className="w-30">
              <h1 className="section-title">Where Clarity meets Action</h1>
              <p className="section-desc">
                Setting goals gives direction and motivation, but consistent
                progress is key. Goalden Hour helps you break down goals into
                actionable steps, keeping you focused on what matters most each
                day.
              </p>
            </div>
          </div>
          <div className="reflection-container row-reverse">
            {window.innerWidth < 768 ? (
              <ReflectionMobileSVG />
            ) : (
              <ReflectionSVG />
            )}
            <div className="w-30">
              <h1 className="section-title">Reflection drives Evolution</h1>
              {window.innerWidth < 768 ? (
                <p className="section-desc">
                  Regularly reviewing your goals is essential for tracking
                  progress and adjusting your strategy. Goalden Hour's weekly
                  check-in helps you stay on course and reflect on your journey.
                </p>
              ) : (
                <p className="section-desc">
                  Regularly reviewing your goals is essential for tracking
                  progress and adjusting your strategy. Goalden Hour's weekly
                  check-in helps you stay on course and reflect on your journey.
                </p>
              )}
            </div>
          </div>
          <div className="bg-gradient">
            <div className="flexBox">
              <div className="items-container h-sm">
                <div className="center-text">
                  <h1 className="section-title">
                    Inspiration at your Fingertips
                  </h1>
                  <p className="section-desc">
                    Many paths lead to success; you just need to find yours.
                    Goalden Hour helps you discover new ways to grow and
                    improve, so you don't have to start from scratch.
                  </p>
                </div>
              </div>
              <div className="inspiration-container">
                {this.inspirationImages.map((image, index) => {
                  return (
                    <div
                      onClick={() => {
                        if (this.props.userStore.currentUser.isAuthenticated) {
                          this.props.history.push("/inspiration");
                        } else {
                          notification.error({
                            message: "Please sign in to access this feature",
                          });
                          // this.props.history.push({
                          //   pathname: "/signin",
                          // });
                        }
                      }}
                      key={index}
                      className="cursor-pointer ins-img-con"
                    >
                      <img src={image.src} alt={image.alt} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="bg-gradient1">
            <div className="flexBox">
              <div className="testimonial-heading">
                <div className="center-text">
                  <h1 className="section-title color-white">Testimonial</h1>
                  <p className="section-desc color-white">
                    See the change. Goalden Hour user experiences
                  </p>
                </div>
              </div>
              <div className="testimonial-container">
                {this.testimonials.map((testimonial, index) => {
                  return (
                    <div key={index} className="testimonial">
                      <p>{testimonial.description}</p>
                      <p className="author">{testimonial.name}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="bg-gradient2">
            <div className="hero-container pt-5">
              <div className="cta-container cta-footer">
                <div className="cta">
                  <h1 className="tagline-footer">
                    Bring Goalden Hour to your group
                  </h1>
                  <p className="description-footer">
                    Transform your team's performance by investing in
                    individuals, fostering accountability, and forging alliances
                    around shared goals
                  </p>
                  <div className="flexBoxRow align-items-center justify-content-center">
                    <a href="https://goalpost.goaldenhour.com/" target="_blank">
                      <IonButton className="home-button" color="quarternary">
                        Get Started
                      </IonButton>
                    </a>
                    {/* {this.renderSignIn({ disableKebabMenu: true })} */}
                  </div>
                </div>
              </div>
              <div className="sun">
                <img
                  className="sun-img"
                  src="/assets/footerMountain.png"
                  alt="mountain"
                />
              </div>
            </div>
          </div>
          <Modal
            width="852px"
            title="Goalden Hour demo"
            visible={this.showModal}
            onCancel={() => (this.showModal = false)}
            footer={""}
          >
            {this.showModal ? (
              <iframe
                width="100%"
                height="480px"
                src="https://www.youtube.com/embed/unbq2sb2taM?si=OjL_1aHhlnE2dpnF"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
            ) : null}
          </Modal>
          <div className="footer-container">
            <div className="footer">
              <Link to="/" className="navbar-link logo-link">
                <BrandLogoLight />
              </Link>
              <button
                className="kebabMenu arrow-button-container"
                onClick={() => {
                  this.scrollToTop();
                }}
              >
                <ArrowUpSVG />
              </button>
            </div>
            <div>
              <p className="footer-text">
                © 2024 Goalden Hour. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </IonPage>
    );
  }
}

export default Home;

// eslint-disable-next-line no-lone-blocks
{
  /* <div className="social-media-icons">
            <a
              className="social-media-icon"
              href="https://www.facebook.com/Goalden-Hour-103115314595100/"
              target="_blank"
            >
              <IonIcon icon={logoFacebook} title="Facebook" />
            </a>
            <a
              className="social-media-icon"
              href="https://www.instagram.com/goaldenhour/"
              target="_blank"
            >
              <IonIcon icon={logoInstagram} title="Instagram" />
            </a>
            <a
              className="social-media-icon"
              href="https://vm.tiktok.com/JjhoA7R/"
              target="_blank"
            >
              <IonIcon icon={logoTiktok} title="TikTok" />
            </a>
            <a
              className="social-media-icon"
              href="https://twitter.com/goalden_hour"
              target="_blank"
            >
              <IonIcon icon={logoTwitter} title="Twitter" />
            </a>
            <a
              className="social-media-icon"
              href="https://medium.com/goalden-hour"
              target="_blank"
            >
              <IonIcon icon={logoMedium} title="Medium" />
            </a>
            <a
              className="social-media-icon"
              href="https://www.linkedin.com/company/goalden-hour"
              target="_blank"
            >
              <IonIcon icon={logoLinkedin} title="LinkedIn" />
            </a>
            </div> */
}

// Packages
import React from "react";
import { action, computed } from "mobx";
import { inject, observer } from "mobx-react";
// Components
import ActivityCard from "../cards/ActivityCard/ActivityCard";
// Type Definitions
import { IGoal, IActivity } from "../interfaces";
import { IonButton, IonModal } from "@ionic/react";
import { AddSVG } from "../../components/svgComponents/AddSVG";
import ActivityForm from "../forms/ActivityForm/ActivityForm";
import moment from "moment";

interface IProps {
  activityStore?: any;
  goal: IGoal;
  startDate?: string;
  endDate?: string;
}

@inject("activityStore")
@observer
class GoalActivities extends React.Component<IProps> {
  @computed get tasks() {
    const tasks = this.props.goal.tasks;
    if (tasks) {
      // Separate tasks by completionType
      const scheduledTasks = tasks.filter(
        (task: any) =>
          task.completionType === "scheduled" && task.status !== "complete"
      );
      const soonTasks = tasks.filter(
        (task: any) =>
          task.completionType === "soon" && task.status !== "complete"
      );
      const somedayTasks = tasks.filter(
        (task: any) =>
          task.completionType === "someday" && task.status !== "complete"
      );
      const completedTasks = tasks.filter(
        (task: any) => task.status === "complete"
      );

      // Sort each category of tasks
      scheduledTasks.sort((a: any, b: any) =>
        moment(a.completionDate).diff(moment(b.completionDate))
      );
      soonTasks.sort((a: any, b: any) =>
        moment(a.createdAt).diff(moment(b.createdAt))
      );
      somedayTasks.sort((a: any, b: any) =>
        moment(a.createdAt).diff(moment(b.createdAt))
      );
      completedTasks.sort((a: any, b: any) =>
        moment(a.updatedAt).diff(moment(b.updatedAt))
      );

      // Combine sorted tasks into one array, with incomplete tasks first
      return [
        ...scheduledTasks,
        ...soonTasks,
        ...somedayTasks,
        ...completedTasks,
      ];
    } else {
      return [];
    }
  }

  @computed get routines() {
    return this.props.goal.routines;
  }

  @action.bound
  public newEmptyActivity = (when: string, type: string, goalId: string) => {
    this.props.activityStore.createActivityType = type;
    this.props.activityStore.createActivityWhen = when;
    this.props.activityStore.activeActivityTemplate = {};
    this.props.activityStore.createActivityModal = true;
    this.props.activityStore.isGoalDetailForm = true;
    this.props.activityStore.goalId = goalId;
  };

  @action.bound
  public closeActivityModal() {
    this.props.activityStore.updateActivityModal = false;
    this.props.activityStore.createActivityModal = false;
  }

  @action.bound
  public removeActivity = (activity: IActivity) => {
    if (this.props.goal.routines) {
      this.props.goal.routines = this.props.goal.routines.filter(
        (routine: IActivity) => routine.id !== activity.id
      );
    }
    if (this.props.goal.tasks) {
      this.props.goal.tasks = this.props.goal.tasks.filter(
        (task: IActivity) => task.id !== activity.id
      );
    }
  };

  render() {
    return (
      <div className="activities-container">
        <div className="card-links header-content-wrapper">
          <p className="small-title goal-routines">ROUTINES</p>
          <IonButton
            size="small"
            color="none"
            onClick={() =>
              this.newEmptyActivity("", "routine", this.props.goal.id)
            }
          >
            <AddSVG color="#0D6EF9" />
          </IonButton>
        </div>
        {this.routines && this.routines.length > 0 && (
          <>
            {this.routines.map((routine: IActivity) => {
              return (
                <ActivityCard
                  key={routine.id}
                  activity={routine}
                  goalName={this.props.goal.name}
                  historicLookup={this.props.endDate}
                  removeActivity={this.removeActivity}
                />
              );
            })}
          </>
        )}
        <div className="card-links header-content-wrapper">
          <p className="small-title goal-routines">TASKS</p>
          <IonButton
            size="small"
            color="none"
            onClick={() =>
              this.newEmptyActivity("", "task", this.props.goal.id)
            }
          >
            <AddSVG color="#0D6EF9" />
          </IonButton>
        </div>
        {this.tasks && this.tasks.length > 0 && (
          <>
            {this.tasks.map((task: IActivity) => {
              return (
                <ActivityCard
                  key={task.id}
                  activity={task}
                  goalName={this.props.goal.name}
                  showFlag={true}
                  removeActivity={this.removeActivity}
                />
              );
            })}
          </>
        )}
        <IonModal
          cssClass={
            this.props.activityStore.createActivityType === "task"
              ? "activity-modal"
              : "routine-modal"
          }
          isOpen={this.props.activityStore.createActivityModal}
          border-radius="7"
          backdropDismiss={false}
        >
          <div className="ion-padding modal-content overflow-y-scroll">
            <ActivityForm
              closeNewActivityModal={this.closeActivityModal}
              goalId={this.props.activityStore.goalId}
            />
          </div>
        </IonModal>
      </div>
    );
  }
}

export default GoalActivities;

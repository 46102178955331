import { observable, action, computed } from "mobx";
import { getData, patchData } from "../plugins/auth/auth";
import { CHECK_INS, CHECK_INS_ROUTE } from "../config";
import { ICheckIn } from "../shared/interfaces";

class CheckInStore {
  // Observables
  @observable
  public baseInit: any = {
    headers: {},
    response: true,
  };
  @observable
  public checkIns: ICheckIn[] = [];
  @observable
  public activeCheckIn: ICheckIn | null = null;
  @observable
  public independentActivities: any[] = [];
  @observable
  public loadingStep: boolean = false;

  // Computed Values
  @computed get incompleteCheckIns() {
    const incompleteCheckIns = this.checkIns.filter(
      (checkIn: ICheckIn) =>
        checkIn.status !== "complete" && checkIn.deleted !== 1
    );
    return incompleteCheckIns.length;
  }

  // Actions
  @action.bound
  public async getCheckIns() {
    const res = await getData(CHECK_INS, CHECK_INS_ROUTE, this.baseInit).catch(
      (err) => {
        console.error("Error from getCheckIns: ", err);
      }
    );

    if (res && res.request.status === 200) {
      this.checkIns = res.data.checkIns.filter((checkIn: ICheckIn) => {
        return checkIn.deleted !== 1;
      });
      return this.checkIns;
    }
  }

  @action.bound
  public async getCheckIn(id: string) {
    const url = `${CHECK_INS_ROUTE}/${id}`;
    const res = await getData(CHECK_INS, url, this.baseInit).catch((err) => {
      console.error("Error from getCheckIn: ", err);
    });
    if (res && res.request.status === 200) {
      this.activeCheckIn = res.data.checkIn;
      this.getCheckInStep(id, parseInt(res.data.checkIn.status.split("-")[1]));
      return this.activeCheckIn;
    }
  }
  @action.bound
  public async getCheckInStep(id: string, step: number) {
    let stepName = step === 1 ? "one" : step === 2 ? "two" : "";
    if (stepName === "") {
      return;
    }
    const url = `${CHECK_INS_ROUTE}/${id}/step-${stepName}`;
    const res = await getData(CHECK_INS, url, this.baseInit).catch((err) => {
      console.error("Error from getCheckIn: ", err);
    });
    if (res && res.request.status === 200 && this.activeCheckIn) {
      this.activeCheckIn.data = res.data.goals;
      this.independentActivities = [
        ...res.data.activitiesWithoutGoal.task,
        ...res.data.activitiesWithoutGoal.routine,
      ].flat(2);
      // if (stepName === "one") {
      //   if (
      //     this.activeCheckIn.data.length === 0 &&
      //     this.independentActivities.length === 0
      //   ) {
      //     setTimeout(() => {
      //       this.incrementStep();
      //     }, 5000);
      //   }
      // }
      return this.activeCheckIn;
    }
  }

  @action.bound
  public async updateCheckIn(
    id: string,
    userId: string,
    status?: string,
    learned?: string,
    enjoyedMost?: string,
    futureChanges?: string,
    deleted?: number
  ) {
    const url = `${CHECK_INS_ROUTE}/${id}`;
    const init = {
      ...this.baseInit,
      body: {
        id,
        userId,
        ...(status && { status }),
        ...(typeof learned === "string" && { learned }),
        ...(typeof enjoyedMost === "string" && { enjoyedMost }),
        ...(typeof futureChanges === "string" && { futureChanges }),
        ...(typeof deleted === "number" && { deleted }),
      },
    };
    const res = await patchData(CHECK_INS, url, init).catch((err) => {
      console.error("Error from updateCheckIn: ", err);
    });
    if (res && res.request.status === 200) {
      this.activeCheckIn = res.data.checkIn;
      this.loadingStep = false;
      return await this.getCheckIn(id);
    }
    this.loadingStep = false;
    return await this.getCheckIns();
  }

  @action.bound
  public async incrementStep() {
    if (this.activeCheckIn) {
      const statusArr = this.activeCheckIn.status.split("-");
      const step = Number(statusArr[1]) + 1;
      if (step === 4) {
        return;
      }
      const res = await this.updateCheckIn(
        this.activeCheckIn.id,
        this.activeCheckIn.userId,
        step === 4 ? "complete" : `step-${step}`
      );
      await this.getCheckInStep(this.activeCheckIn.id, step);
      return res;
    } else {
      console.error("No check-in found for update operation");
    }
  }

  @action.bound
  public async revertStep() {
    if (this.activeCheckIn) {
      let newStatus;
      if (this.activeCheckIn.status === "complete") {
        newStatus = "step-3";
      } else {
        const statusArr = this.activeCheckIn.status.split("-");
        const step = Number(statusArr[1]) - 1;
        newStatus = `step-${step}`;
      }

      this.updateCheckIn(
        this.activeCheckIn.id,
        this.activeCheckIn.userId,
        newStatus
      );
    } else {
      console.error("No check-in found for update operation");
    }
  }
}

export default new CheckInStore();
